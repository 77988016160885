<template>
  <!-- <v-list-item class="mb-0 justify-space-between pl-3">
    <a href="https://www.softclient.com.au/">
      <v-list-item-avatar>
        <v-img :src="require('@/assets/sclogo.jpg')" />
      </v-list-item-avatar>
    </a>
    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-black">my</strong>

        <span class="primary--text">Orders</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item> -->
  <!-- <div class="d-flex justify-center px-2 my-4">
    <v-img
      :src="require('@/assets/myMuscleChef.png')"
      width="80"
    />
  </div> -->
  <div class="d-flex justify-center px-2 my-4">
    <v-img :src="require('@/assets/myMuscleChef.png')" width="80" />
  </div>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: {
    version: get("app/version"),
    logoImage() {
      return (
        "https://filesstorage.azurewebsites.net/img/" +
        window.location.host +
        "-logo.png"
      );
    },
  },
};
</script>
